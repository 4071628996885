import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import SEO from '../components/SEO'
import HeroHeader from '../components/HeroHeader'
import YoutubeVideo from '../components/YoutubeVideo'

const title = 'Medien | Stadlwirt'

interface Media {
  type: 'image' | 'video'
}

interface VideoMedia extends Media {
  type: 'video'
  title: string
  youtubeVideoId: string
}

interface ImageMedia extends Media {
  type: 'video'
  title: string
  /**
   * Url relative to public folder, starting with "/"
   */
  url: string
}

const media: (ImageMedia | VideoMedia)[] = [
  {
    type: 'video',
    title: 'Urlaub in Nauders 2020',
    youtubeVideoId: 'TyOg5ga5cnE',
  },
  {
    type: 'video',
    title: 'Kaiserschützenweg in Nauders',
    youtubeVideoId: 'XrdflDrCbrY',
  },
  {
    type: 'video',
    title: 'Paradies Nauders',
    youtubeVideoId: 'nv6lJr6dHkk',
  },
]

interface VideoProps {
  video: VideoMedia
}

const Video = ({ video }: VideoProps) => {
  const { title, youtubeVideoId } = video

  return (
    <div className="container text-center" key={youtubeVideoId}>
      <h2 className="text-xl font-semibold">{title}</h2>
      <hr className="my-2 mx-auto mb-3" />
      <YoutubeVideo
        className="max-w-3xl pb-1 mx-auto"
        videoId={youtubeVideoId}
      />
    </div>
  )
}

const MediaPage = () => {
  const { fluidBackgroundImage } = useStaticQuery(
    graphql`
      query {
        fluidBackgroundImage: file(relativePath: { eq: "dslr_mountains.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `,
  )

  return (
    <>
      <SEO title={title} />
      <HeroHeader
        title="Medien"
        staticBackgroundImage="/uploads/dslr_mountains.jpg"
        fluidBackgroundImage={fluidBackgroundImage}
      />
      <section className="py-10">
        <div className="container text-center">
          <p className="max-w-2xl mx-auto mb-5">
            Neben der Liebe zur Kulinarik versucht sich der Stadlwirt in seiner
            Freizeit auch gerne als Fotograf und Filmemacher. Ausgewählte
            Exemplare können sie hier einsehen!
          </p>
        </div>
        {media.map((media) => {
          const { type, title } = media

          switch (type) {
            case 'video':
              return <Video key={title} video={media as VideoMedia} />
            default:
              return null
          }
        })}
      </section>
    </>
  )
}

export default MediaPage
